.owl-dots {
    text-align: center;
}

#carousel-slides {
    .owl-dots {
        position: absolute;
        bottom: 8rem;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: 992px) {
        .owl-dots {
            bottom: 2rem;
        }
    }

    .owl-nav {
        position: absolute;
        top: 50%;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 70vw;

        button {
            background-color: #d20a2a !important;
            border-radius: 50% !important;
            color: white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
        }

        button:hover {
            background-color: #b00824 !important;
        }
    }

    .slide-media {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Ensures the video or image covers the container without stretching */
    }

    .item {
        position: relative;
        width: 100%;
        // height: 1000px;

        overflow: hidden;
        /* Ensures content doesn't overflow the container */
    }

    .video {
        display: block;
    }
}

#carousel-life,
#carousel-topsil {
    .owl-nav {
        position: absolute;
        top: 50%;
        display: flex;
        width: 100%;
        gap: 70vw;
        justify-content: center;

        button {
            background-color: #d20a2a !important;
            border-radius: 50% !important;
            color: white !important;
            font-size: 20px !important;
            width: 40px !important;
            height: 40px !important;

            i {
                width: 40px !important;
            }
        }
        

        button:hover {
            background-color: #b00824 !important;
        }
    }
}

#carousel-contest-current {
    .owl-nav {
        position: absolute;
        top: 50%;
        display: flex;
        width: 100%;
        gap: 55vw;
        justify-content: center;

        button {
            background-color: #d20a2a !important;
            border-radius: 50% !important;
            color: white !important;
            font-size: 20px !important;
            width: 40px !important;
            height: 40px !important;

            i {
                width: 40px !important;
            }
        }

        button:hover {
            background-color: #b00824 !important;
        }
    }

    @media (max-width: 992px) {
        .owl-nav {            
            gap: 70vw;
        }
    }
}

#carousel-contest-past {
    .owl-dots {
        margin-top: 30px;
    }
}

.owl-dot {
    span {
        @apply inline-block w-3 h-3 rounded-full bg-primary #{!important};
    }

    &.active span {
        @apply w-6 #{!important};
    }

    & + .owl-dot {
        @apply ms-2;
    }
}
