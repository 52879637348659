
@use "./base/animations";
@use "./layout/header";
@use "./modules/carousels";

@tailwind base;
@tailwind components;
@tailwind utilities;


